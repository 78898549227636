import styled from "styled-components"

const OpinionStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  /* margin: 0 auto; */
  .oppinionAll {
    width: 100%;
    text-align: center;
    padding-top: 40px;
    a {
      text-decoration: none;
      color: #05b7ba;
    }
  }
  .slick__wrapper {
    max-width: calc(100% - 80px);
    width: 900px;
    margin-top: 20px;
    @media (max-width: 540px) {
      max-width: calc(100% - 40px);
    }

    * {
      outline: none !important;
    }
  }
  .arrow {
    &__left {
      img {
        filter: brightness(0.5);
        width: 20px;
        height: 20px;
        display: block;
        cursor: pointer;
      }
    }

    &__right {
      img {
        filter: brightness(0.5);
        width: 20px;
        height: 20px;
        display: block;
        cursor: pointer;
      }
    }
  }
  .slide {
    padding: 50px 100px 50px 60px;
    background: #fff;
    @media (max-width: 940px) {
      padding: 30px 30px 30px 30px;
    }
    &__description {
      padding-bottom: 50px;
      line-height: 1.6;
      color: #7c7c7c;
    }
    &__footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      @media (max-width: 540px) {
        flex-direction: column;
      }
    }
    &__about {
      max-width: 280px;
      @media (max-width: 480px) {
        max-width: 100%;
      }
    }
    &__img {
      margin-right: 20px;
      @media (max-width: 480px) {
        margin-right: 0;
        padding-bottom: 20px;
      }
    }
    &__name {
      font-weight: 600;
    }
    &__position {
      color: #05b7ba;
    }
  }
`
export default OpinionStyled
