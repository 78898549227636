import styled from "styled-components"

const TableStyled = styled.div`
  max-width: 900px;
  margin: 0 auto;
  background: #fff;
  .row {
    display: flex;
    width: 100%;
    padding: 20px;
    @media (max-width: 940px) {
      flex-wrap: wrap;
    }
    &:nth-of-type(odd) {
      background: #05b7ba;
      color: #fff;
    }
    &:nth-of-type(even) {
      color: #7c7c7c;
      b,
      strong {
        color: #191c1d;
      }
    }
  }
  .col {
    &__icon {
      width: 50px;
      padding-top: 5px;
      .gatsby-image-wrapper {
        width: 20px;
        height: 20px;
      }
      @media (max-width: 940px) {
        display: none;
      }
    }
    &__time {
      width: 130px;
      line-height: 1.9;
    }
    &__description {
      width: calc(100% - 180px);
      line-height: 1.9;
      @media (max-width: 940px) {
        width: 100%;
      }
    }
  }
`

export default TableStyled
