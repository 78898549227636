import styled from "styled-components"
const HistoryBuilderStyled = styled.div`
  .history__wrapper {
    position: relative;
    &:before {
      content: "";
      left: 20px;
      height: 100%;
      background: #05b7ba;
      width: 3px;
      position: absolute;
    }
  }
  .singleDate {
    display: flex;
    justify-content: space-between;
    padding-bottom: 2em;
    position: relative;
    &:last-of-type {
      &:before {
        content: "";
        left: 20px;
        height: 100%;
        max-height: 500px !important;
        background: #fff;
        width: 3px;
        position: absolute;
        @media (max-width: 330px) {
          max-height: 540px !important;
        }
      }
    }
    &:before {
      content: "";
      left: 20px;
      height: 100%;
      max-height: 500px;
      transition: 0.3s all 0.2s;
      background: #fff;
      width: 3px;
      position: absolute;
      bottom: 0;
    }
    &.show {
      &:before {
        max-height: 0px;
      }
      .singleDate__description {
        opacity: 1;
      }
      .singleDate__date {
        opacity: 1;
      }
    }
    &__date {
      width: 40px;
      border-top: 2px solid #05b7ba;
      border-left: 2px solid #05b7ba;
      border-right: 2px solid #05b7ba;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      padding: 5px 2px 0;
      background: #fff;
      position: relative;
      opacity: 0;
      transition: 0.3s all;
      &:after {
        content: "";
        border: solid #05b7ba;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 13px;
        position: absolute;
        margin-top: 25px;
        margin-left: 0.5px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        background: #fff;
      }
    }
    &__month {
      font-size: 10px;
      font-weight: 600;
      background: #fff;
      position: relative;
      z-index: 2;
    }
    &__year {
      font-size: 10px;
      font-weight: 600;
      background: #fff;
      position: relative;
      z-index: 2;
    }
    &__description {
      opacity: 0;
      max-width: calc(100% - 70px);
      color: #7c7c7c;
      line-height: 1.7;
      transition: 0.3s all 0.5s;
      ul {
        list-style: none;
        li:before {
          content: "";
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: #05b7ba;
          display: block;
          position: absolute;
          left: -20px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      li {
        position: relative;
        margin: 0;
      }
    }
  }
`
export default HistoryBuilderStyled
