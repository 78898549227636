import React, { useState } from "react"
import VisibilitySensor from "react-visibility-sensor"

const ImgHeadingHeading = ({ text }) => {
  const [isVisible, setVisibility] = useState(false)

  const onChange = visiblity => {
    if (!!visiblity) {
      setVisibility(visiblity)
    }
  }

  return (
    <VisibilitySensor scrollCheck={true} partialVisibility onChange={onChange}>
      <div className={`heading ${isVisible ? "show" : ""}`}>
        <div
          //   className={isVisible ? "show" : ""}
          dangerouslySetInnerHTML={{ __html: text }}
        ></div>
      </div>
    </VisibilitySensor>
  )
}

export default ImgHeadingHeading
