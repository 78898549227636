import styled from "styled-components"
const ImgHeadingStyled = styled.div`
  padding: 22.5% 0 0;
  position: relative;
  margin-bottom: 22.5%;
  display: block;
  @media (max-width: 940px) {
    padding-bottom: 40%;
    margin-bottom: 0;
  }
  @media (max-width: 680px) {
    margin-bottom: 15%;
    margin-top: 15%;
  }
  @media (max-width: 480px) {
    margin-bottom: 0%;
    padding-bottom: 45%;
    margin-top: 0%;
    padding-top: 190px;
  }
  @media (max-width: 350px) {
    padding-top: 220px;
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    ${props =>
      props.topBackground === "dark"
        ? "background: #191c1d;"
        : "background: #fff;"}
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    ${props =>
      props.bottomBackground === "dark"
        ? "background: #191c1d;"
        : "background: #fff;"}
  }
  .container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }
  .gatsby-image-wrapper {
    position: absolute !important;
    max-width: 100%;
    max-height: 120%;
    @media (max-width: 480px) {
      max-height: 100%;
    }
  }
  .heading {
    height: 100%;
    display: block;
    margin-top: 25%;
    transform: translateY(-50%) scale(0.4);
    opacity: 0;
    transition: 0.3s all 0.2s;
    @media (max-width: 940px) {
      margin-top: 35%;
      padding: 0 30px;

      br {
        display: none;
      }
    }
    @media (max-width: 480px) {
      margin-top: 170px;
      min-height: 11rem;
    }
    &:after {
      content: "";
      position: relative;
      width: 160px;
      height: 1px;
      background: #fff;
      margin: 40px auto 0;
      display: block;
    }
    &.show {
      transform: translateY(-50%) scale(1);
      opacity: 1;
    }
    /* 
    div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: 0.3s all 0.2s;
    } */
  }
  p {
    margin-top: 40px;
    color: #fff;
    line-height: 30px;
  }
  h2 {
    color: #fff;
    font-size: 60px;
    line-height: 80px;
    @media (max-width: 1180px) {
      font-size: 45px;
      line-height: 60px;
    }
    @media (max-width: 940px) {
      font-size: 35px;
      line-height: 40px;
    }
    @media (max-width: 540px) {
      font-size: 28px;
      line-height: 32px;
    }
    @media (max-width: 450px) {
      font-size: 25px;
      line-height: 28px;
    }
  }
  h1 {
    color: #fff;
    font-size: 48px;
    line-height: 1.5;
    font-weight: 900;
    @media (max-width: 1180px) {
      font-size: 40px;
      line-height: 60px;
    }
    @media (max-width: 940px) {
      font-size: 32px;
      line-height: 40px;
    }
    @media (max-width: 540px) {
      font-size: 25px;
      line-height: 32px;
    }
    @media (max-width: 450px) {
      font-size: 22px;
      line-height: 28px;
    }
  }
`
export default ImgHeadingStyled
