import React, { useState } from "react"
import VisibilitySensor from "react-visibility-sensor"

const SingleDate = ({ year, month, description }) => {
  const [isVisible, setVisibility] = useState(false)

  const onChange = visiblity => {
    if (!!visiblity) {
      setVisibility(visiblity)
    }
  }
  return (
    <VisibilitySensor
      scrollCheck={true}
      //   partialVisibility
      onChange={onChange}
      offset={{ top: 30 }}
    >
      <div className={`singleDate ${isVisible ? "show" : ""}`}>
        <div className="singleDate__date">
          <p className="singleDate__month">{year}</p>
          <p className="singleDate__year">{month}</p>
        </div>
        <div
          className="singleDate__description"
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>
      </div>
    </VisibilitySensor>
  )
}

export default SingleDate
