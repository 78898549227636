import styled from "styled-components"
const HeadingStyled = styled.div`
  padding-bottom: 100px;
  .heading__compoment {
    &--1 {
      text-align: center;
      font-weight: 400;
      font-size: 48px;
      @media (max-width: 1180px) {
        font-size: 40px;
        line-height: 38px;
        padding-bottom: 30px;
      }
      @media (max-width: 540px) {
        font-size: 35px;
        line-height: 30px;
      }
      @media (max-width: 450px) {
        font-size: 30px;
        line-height: 28px;
      }
    }
    &--2 {
      text-align: center;
      font-weight: 400;
      font-size: 32px;
      @media (max-width: 1180px) {
        font-size: 30px;
      }
      @media (max-width: 540px) {
        font-size: 28px;
      }
      @media (max-width: 450px) {
        font-size: 25px;
      }
    }
    &--3 {
      text-align: center;
      font-weight: 400;
      font-size: 24px;
    }
  }
`
export default HeadingStyled
