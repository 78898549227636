import styled from "styled-components"
const RowFullStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 70px;
  position: relative;
  padding-top: 7vh;
  @media (max-width: 940px) {
    flex-wrap: wrap;
  }
  &.row__startPosition {
    &--left {
      flex-direction: row;
      .row__images {
        display: flex;
      }
      .gatsby-image-wrapper:after {
        right: 0;
        left: initial;
      }
      &:nth-of-type(2n) {
        flex-direction: row-reverse;
        .row__images {
          display: flex;
          justify-content: flex-end;
        }
        .gatsby-image-wrapper:after {
          left: 0;
          right: initial;
          transform-origin: left center;
        }
      }
    }
    &--right {
      flex-direction: row-reverse;
      .row__images {
        display: flex;
        justify-content: flex-end;
      }
      .gatsby-image-wrapper:after {
        left: 0;
        right: initial;

        transform-origin: left center;
      }

      &:nth-of-type(2n) {
        .row__content {
          margin-left: auto;
        }
        flex-direction: row;
        .row__images {
          display: flex;
        }
        .gatsby-image-wrapper:after {
          right: 0;
          left: initial;
          transform-origin: right center;
        }
      }
    }
    &--left {
        flex-direction: row;
        .row__content {
            margin-left: auto;
          }
        .row__images {
          display: flex;
          justify-content: flex-end;
        }
        .gatsby-image-wrapper:after {
          left: 0;
          right: initial;
          transform-origin: left center;
        }

        &:nth-of-type(2n) {
          flex-direction: row-reverse;
          .row__content {
            /* margin-left: auto; */
          }
          .row__images {
            display: flex;
          }
          .gatsby-image-wrapper:after {
            right: 0;
            left: initial;
            transform-origin: right center;
          }
        }
      }
    }
  }
  .row__images {
    width: calc(50% - 20px);
    max-width: 730px;
    @media (max-width: 940px) {
      width: 100%;
      padding-bottom: 60px;
    }
    div {
      max-width: 100%;
    }
  }
  .container {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    @media (max-width: 940px) {
      position: relative;
      left: initial;
      right: initial;
      top: initial;
      transform: initial;
    }
  }
  .row__content {
    width: calc(50% - 20px);
    max-width: 600px;
    @media (max-width: 940px) {
      max-width: initial;
      width: 100%;
    }
  }
  .row__button {
    padding-top: 50px;
  }

  .row {
    &__subheading {
      color: #05b7ba;
      font-weight: 600;
      padding-bottom: 30px;
      position: relative;
      &:after {
        content: "";
        margin-top: 20px;
        height: 1px;
        width: 170px;
        background: #e0e0e0;
        display: block;
      }
    }
    &__heading {
      font-size: 36px;
      line-height: 48px;
      padding-bottom: 40px;
      @media (max-width: 1180px) {
        font-size: 28px;
        line-height: 38px;
        padding-bottom: 30px;
      }
      @media (max-width: 540px) {
        font-size: 25px;
        line-height: 30px;
      }
      @media (max-width: 450px) {
        font-size: 22px;
        line-height: 28px;
      }
    }
    &__para {
      color: #7c7c7c;
      line-height: 30px;
      strong {
        color: #05b7ba;
        font-weight: 400;
      }
    }
  }
  &:hover {
    .gatsby-image-wrapper {
      &:after {
        -webkit-transform: translateY(-50%) scale(1, 1);
        -ms-transform: translateY(-50%) scale(1, 1);
        transform: translateY(-50%) scale(1, 1);
      }
    }
  }
`
export default RowFullStyled
