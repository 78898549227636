import React from "react"
import Img from "gatsby-image"
import ImagesWrapperStyled from "./styles/ImagesWrapperStyles"

const ImagesWrapper = ({ data }) => {
  return (
    <ImagesWrapperStyled>
      <Img fixed={data.localFile.childImageSharp.fixed} alt={data.title} />
    </ImagesWrapperStyled>
  )
}

export default ImagesWrapper
