import styled from "styled-components"

const BoxesStyled = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 100px;
  @media (max-width: 940px) {
    flex-wrap: wrap;
    justify-content: center;
  }
  .box {
    max-width: 650px;
    position: relative;
    width: calc(50% - 20px);
    @media (max-width: 940px) {
      width: 100%;
      margin-bottom: 30px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    &__content {
      position: absolute;
      bottom: 50px;
      left: 50%;
      max-width: 560px;
      transform: translateX(-50%);
      width: 100%;
      @media (max-width: 1400px) {
        padding: 0 20px 0;
      }
    }
    &__background {
      max-width: 100%;
    }
    &__text {
      color: #fff;
      p {
        font-size: 24px;
      }
      strong {
        font-weight: 600;
      }
    }
    &__icon {
      padding-bottom: 30px;
    }
  }
`

export default BoxesStyled
