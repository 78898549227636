import React, { useState, useRef } from "react"
import Slider from "react-slick"
import Img from "gatsby-image"
import { Helmet } from "react-helmet"
import ArrowLeft from "../images/arrow_left.svg"
import ArrowRight from "../images/arrow_right.svg"

const Opinion = ({ data, seeAll }) => {
  const [slideIndex, setSlideIndex] = useState(0)
  const slider = useRef()
  const settings = {
    beforeChange: (current, next) => setSlideIndex(next),
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // vertical: true,
    // verticalSwiping: true,
    fade: false,
    arrows: false,
    // autoplay: true,
    // autoplaySpeed: 5000,
  }
  //   name
  //   posotion
  //   description
  return (
    <>
      <Helmet defer={false}>
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>
      <div
        className="slick__arrow arrow__left"
        id="arrow__left"
        onClick={() => {
          slider.current.slickPrev()
        }}
      >
        <img src={ArrowLeft} alt="arrow" />
      </div>
      <div className="slick__wrapper">
        <Slider ref={slider} {...settings}>
          {data.map((item, index) => (
            <div key={`slide__${index}`} className="slide">
              <div
                className="slide__description"
                dangerouslySetInnerHTML={{ __html: item.description }}
              ></div>
              <div className="slide__footer">
                {item.img ? (
                  <div className="slide__img">
                    <Img fixed={item.img.localFile.childImageSharp.fixed} />
                  </div>
                ) : (
                  ""
                )}
                <div className="slide__about">
                  <p className="slide__name">{item.name}</p>
                  <p className="slide__position">{item.posotion}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div
        className="slick__arrow arrow__right"
        id="arrow__right"
        onClick={() => {
          slider.current.slickNext()
        }}
      >
        <img src={ArrowRight} alt="arrow" />
      </div>
      <div className="oppinionAll">
        <a href={seeAll} target="_blank">
          Zobacz wszystkie
        </a>
      </div>
    </>
  )
}

export default Opinion
