import styled from "styled-components"

const ImagesWrapperStyled = styled.div`
  position: relative;
  max-width: 100%;
  width: 100%;
  .gatsby-image-wrapper {
    max-width: 100%;
    @media (max-width: 940px) {
      width: 100% !important;
      max-height: 370px;
    }
    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%) scale(0.1, 0.15);
      transform-origin: right center;
      transform-box: fill-box;
      width: 100%;
      height: 100%;
      display: block;
      background: #05b7ba;
      opacity: 0.7;
      transition: 0.3s all linear;
      mix-blend-mode: multiply;
    }
    &:hover {
      &:after {
        transform: translateY(-50%) scale(1, 1);
      }
    }
  }
`

export default ImagesWrapperStyled
